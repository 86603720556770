<template>
    <a-drawer @close="close" :visible="addressModal" title="选择可配送区域" width="50%" class="modal" >
      <a-row :span="24" type="flex">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="item">
          <div class="acea-row row-right row-middle">
            <a-checkbox v-model="iSselect" @change="allCheckbox">全选</a-checkbox>
            <span class="empty padding-left-sm" @click="empty">清空</span>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          justify="center"
          class="item"
          v-for="(item, index) in cityList"
          :key="index"
        >
          <div @mouseenter="enter(index)" @mouseleave="leave()">
            <a-checkbox v-model="item.checked" :disabled="item.disabled" :label="item.areaName" @change="checkedClick(index)">
              {{item.areaName}}
            </a-checkbox>
            <span class="red">({{ (item.count || 0) + '/' + item.childNum }})</span>
            <div class="city" v-show="activeCity === index">
              <div class="checkBox">
                <div class="arrow"></div>
                <div>
                  <a-checkbox
                    v-model="city.checked"
                    :label="city.areaName"
                    @change="primary(index, indexn)"
                    class="itemn"
                    v-for="(city, indexn) in item.children"
                    :key="indexn"
                    v-show="city.isShow"
                    :disabled="city.disabled"
                    >{{ city.areaName }}</a-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="foot-btn">
        <a-button @click="close" class="margin-right-sm">取消</a-button>
        <a-button type="primary" @click="confirm">确定</a-button>
      </div>
    </a-drawer>
</template>

<script>
import * as logistics from '@/api/logistics'
import { mapGetters } from 'vuex'
export default {
  name: 'city',
  props: {
    type: {
      type: Number,
      default: 1,
    },
    id: {
      id: [Number, String],
      default: 0,
    },
    selectArr: {
      type: Array,
      default: [],
    },
    otherSelectedArr: {
      type: Array,
      default: [],
    }
    // acityList: {
    //   type: Array,
    //   default: [],
    // }
  },
  data() {
    return {
      iSselect: false,
      addressModal: false,
      activeCity: -1,
      loading: false,
      cityList: []
    };
  },
  computed: {
    ...mapGetters(['acityList'])
  },
  watch: {
    addressModal(newVal) {window.console.log('addressModal addressModal',newVal,this.acityList)
      if(newVal) {
        this.cityList = JSON.parse(JSON.stringify(this.acityList))
        this.cityList.forEach((item, index) => {
          item.isShow = true;
          /**选中的城市回显 */
          item.children.forEach((item1, index1) => {
            item1.isShow = true;
            if (this.selectArr.length > 0) {
              this.selectArr.forEach((item3, index3) => {
                item3.children.forEach((item4, index4) => {
                  if (item1.areaId == item4.areaId) {
                    this.$set(this.cityList[index], 'checked', true )
                    this.$set(this.cityList[index].children[index1], 'checked', true )
                  }
                });
              });
            }
            /**otherSelectedArr 将其他已经选中的城市 置灰 */
            if(this.otherSelectedArr.length > 0) {
              this.otherSelectedArr.forEach((item3, index3) => {
                item3.children.forEach((item4, index4) => {
                  if (item1.areaId == item4.areaId) {
                    this.$set(this.cityList[index], 'disabled', true )
                    this.$set(this.cityList[index].children[index1], 'disabled', true )
                  }
                });
              });
            }
          });
        });
        /** 选中的城市数量计算*/
        this.cityList.forEach((item) => {
          let num = 0;
          let oldNum = 0;
          item.children.forEach((item1) => {
            if (!item1.isShow) {
              num++;
            } else {
              oldNum++;
            }
          });
          if (num == item.children.length) {
            item.isShow = false;
          }
          item.childNum = oldNum;
        });
      }
    }
  },
  methods: {
    enter(index) {
      this.activeCity = index;
    },
    leave() {
      this.activeCity = null;
    },
    /**
     * 全选或者反选
     * @param checked
     */
    allCheckbox: function () {
      let that = this, checked = this.iSselect;
      that.cityList.forEach(function (item, key) {
        if(!item.disabled) that.$set(that.cityList[key], 'checked', checked);
        if (checked) {
          if(!item.disabled) that.$set(that.cityList[key], 'count', that.cityList[key].children.length);
        } else {
          that.$set(that.cityList[key], 'count', 0);
        }
        that.cityList[key].children.forEach(function (val, k) {
          /**otherSelectedArr 全选时其他已经选中的城市不可以被选中  */
          if(!that.cityList[key].children[k].disabled) that.$set(that.cityList[key].children[k], 'checked', checked);
        });
      });
      // this.render();
    },
    // 清空；
    empty() {
      let that = this;
      that.cityList.forEach(function (item, key) {
        that.$set(that.cityList[key], 'checked', false);
        that.cityList[key].children.forEach(function (val, k) {
          that.$set(that.cityList[key].children[k], 'checked', false);
        });
        that.$set(that.cityList[key], 'count', 0);
      });
      this.iSselect = false;
    },
    /**
     * 点击省
     * @param index
     */
    checkedClick: function (index) {
      let that = this;
      if (that.cityList[index].checked) {
        that.$set(that.cityList[index], 'count', that.cityList[index].childNum);
        that.cityList[index].children.forEach(function (item, key) {
          that.$set(that.cityList[index].children[key], 'checked', true);
        });
      } else {
        that.$set(that.cityList[index], 'count', 0);
        that.$set(that.cityList[index], 'checked', false);
        that.cityList[index].children.forEach(function (item, key) {
          that.$set(that.cityList[index].children[key], 'checked', false);
        });
        that.iSselect = false;
      }
      // this.render();
    },
    /**
     * 点击市区
     */
    primary: function (index, ind) {
      let checked = false,
        count = 0;
      this.cityList[index].children.forEach(function (item, key) {
        if (item.checked) {
          checked = true;
          count++;
        }
      });
      this.$set(this.cityList[index], 'count', count);
      this.$set(this.cityList[index], 'checked', checked);
      // this.render();
    },
    // 确定
    confirm() {
      let that = this;
      // 被选中的省市；
      let selectList = [];
      that.cityList.forEach(function (item, key) {
        let data = {};
        if (item.checked) {
          data = {
            areaName: item.areaName,
            areaId: item.areaId,
            cityNum: item.cityNum,
            children: [],
          };
        }
        that.cityList[key].children.forEach(function (i, k) {
          if (i.checked) {
            data.children.push({
              areaId: i.areaId,
              areaName: i.areaName
            });
          }
        });
        if (data.areaId !== undefined) {
          selectList.push(data);
        }
      });
      if (selectList.length === 0) {
        return that.$message.error('至少选择一个省份或者城市');
      } else {
        window.console.log('selectList',selectList)
        this.$emit('selectCity', {
          id: this.id,
          selectList: selectList,
          type: this.type, // 0添加 1编辑
          isEdit: this.selectArr.length ? true : false,
          iSselect: this.iSselect
        });
        that.addressModal = false;
        this.cityList = [];
      }
    },
    close() {
      this.addressModal = false;
      this.cityList = [];
    },

    /**
     * 获取城市tree结构
     */
     getCityList() {
      return;
      this.loading = true;
      logistics.cityListTree().then((res) => {
        this.loading = false;
        this.selectArr = [];
        res.data.forEach((el, index, arr) => {
          el.isShow = true;
          el.children.forEach((child, j) => {
            child.isShow = true;
            if (this.selectArr.length > 0) {
              this.selectArr.forEach((sel, sindex) => {
                sel.children.forEach((sitem, sj) => {
                  if (child.areaId == sitem.areaId) {
                    child.isShow = false;
                  }
                });
              });
            }
          });
        });
        res.data.forEach((el, index, arr) => {
          let num = 0;
          let oldNum = 0;
          el.children.forEach((child, j) => {
            if (!child.isShow) {
              num++;
            } else {
              oldNum++;
            }
          });
          if (num == el.children.length) {
            el.isShow = false;
          }
          el.childNum = oldNum;
        });
        this.cityList = res.data;
      });
    },
  }
};
</script>

<style scoped lang="less">
.modal .item {
  margin-bottom: 20px;
  position: relative;
}

.modal .item .city {
  position: absolute;
  z-index: 9;
  top: 17px;
  padding-top: 18px;
}

.modal .item .city .checkBox {
  width: 97%;
  padding: 10px;
  border: 1px solid #eee;
  background-color: #fff;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal .item .city .checkBox .arrow {
  position: absolute;
  top: 3px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #ddd;
}

.modal .item .city .checkBox .arrow:before {
  position: absolute;
  bottom: -8px;
  right: -7px;
  content: '';
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: #fff;
}

.modal .item .city .checkBox .itemn {
  margin-bottom: 10px;
}

.radio {
  padding: 5px 0;
  font-size: 14px !important;
}

.red {
  color: #ff0000;
}

.empty {
  cursor: pointer;
}
</style>
